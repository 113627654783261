import { Box, Button, Modal } from "@mui/material";
import { useRef, useState } from "react";
import { getPatientReport } from "../api/routes";
import RequestTable from "./RequestTable";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
};

export default function PatientModal({ patientModalOpen, handleClosePatient }) {
    const [codeReception, setCodeReception] = useState("");
    const submitRef = useRef(null)
    const numberRegex = /^[0-9]*$/g;
    const [loading, setLoading] = useState(false)
    const [patientData, setPatientData] = useState([]);
    const [requests, setRequests] = useState([])

    const codeReceptionChange = (event) => {
        if (event.target.value === '')
            return setCodeReception(event.target.value)
        else if (event.target.value.match(numberRegex)) {
            return setCodeReception(event.target.value)
        } else return alert('عدد وارد کنید!')
    }
    const onPatientClickEnter = (event) => {
        if (event.key === 'Enter' && codeReception !== '') {
            event.preventDefault();
            event.stopPropagation();
            submitRef.current.click()
        }
    }
    const patientReport = () => {
        setLoading(true)
        getPatientReport(codeReception, (isOk, data) => {
            setLoading(false)
            if (!isOk) {
                return alert(data.message);
            }
            else {
                if (data.length === 0) {
                    alert('بیمار یافت نشد!')
                }
                else {
                    setRequests(data.map((item, index) => ({ id: index, name: item.NameFarsi })));
                    setPatientData(data[0]);
                }
            }
        })
    }

    return (
        <Modal open={patientModalOpen} onClose={handleClosePatient}>
            <Box className="w-full sm:w-auto" sx={style}>
                {patientData.length === 0 ? <section>
                    <p className='font-bold mb-4 text-base sm:text-lg'>براي مشاهده گزارش به نكات ذيل توجه نماييد:</p>
                    <p className='text-sm sm:text-base'>• در كادر پايين شماره پذيرش بيمار را وارد نموده و كليد ادامه را بزنيد.</p>
                    <p className='my-4 text-sm sm:text-base'>• بعد از آماده شدن گزارش، پيامكي حاوي لينك مشاهده گزارش به تلفن همراه بيمار ارسال مي گردد.</p>
                    <p className='text-sm sm:text-base'>• درصورت داشتن سوال مي توانيد با شماره تلفن <a href='tel:05138883'>05138883</a> تماس بگيريد.</p>
                    <div className='flex items-center mt-8'>
                        <input onKeyDown={onPatientClickEnter} type='tel' value={codeReception} onChange={codeReceptionChange} placeholder="شماره پذیرش" className='flex-1 text-center border rounded-lg h-[44px] px-4' />
                        <button ref={submitRef} type="button" onClick={patientReport} className='focus:outline-none text-white bg-[#fa3668] font-medium rounded-lg px-8 sm:px-12 py-2.5 mr-4'>ادامه</button>
                    </div>
                </section> :
                    <section className='sm:w-[450px] relative'>
                        <img src='/images/back-arrow.png' alt='back-arrow' className='absolute -left-5 sm:-left-7 -top-6 w-9 cursor-pointer' onClick={() => setPatientData([])} />
                        <p className='sm:text-base text-sm font-bold'><span className='font-normal'>تاریخ مراجعه: </span>{patientData.DateReception}</p>
                        <p className='sm:text-base my-2 text-sm font-bold'><span className='font-normal'>مراجعه کننده محترم: </span>{patientData.fullName}</p>
                        <p className='sm:text-base text-sm font-bold'><span className='font-normal'>نوع خدمت: </span>{patientData.NameGroups}</p>

                        <section className='flex flex-col items-center mt-6'>
                            <Button disabled={loading} className='w-3/4' variant="outlined" style={{ color: "#068dd6", borderColor: "#068dd6" }}>
                                <img src='/images/word.png' alt='report' className='cursor-pointer w-10 ml-4' />
                                <p className='sm:text-base text-sm'>مشاهده جواب</p>
                            </Button>
                            <Button disabled={loading} className='mt-2 w-3/4 opacity-30' variant="outlined" style={{ color: "#ccc", borderColor: "#ccc" }}>
                                <img src='/images/pic.png' alt='pacs' className={`cursor-pointer w-10 ml-4`} />
                                <p className='sm:text-base text-sm'>مشاهده تصاویر</p>
                            </Button>
                        </section>

                        <p className='mt-4 mb-2 sm:text-base text-sm'>جزئیات درخواست:</p>
                        <RequestTable data={requests} />
                    </section>}
            </Box>
        </Modal>
    )
}