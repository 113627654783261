import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import FloatingBtn from './components/FloatingBtn';
import DoctorPatients from './pages/DoctorPatients';
import Home from './pages/Home';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"*"} element={<Navigate to={'/'} />} />
          <Route path={"/doctor-patients"} element={<DoctorPatients />} />

        </Routes>
      </BrowserRouter>
      <FloatingBtn />
    </>
  );
}

export default App;
