import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import { Backdrop, CircularProgress, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, Sidebar, useProSidebar } from "react-pro-sidebar";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getDoctorPatients } from '../api/routes';
import Profile from '../components/Profile';
import PatientTable from './../components/PatientTable';

export default function DoctorPatients() {
    const navigate = useNavigate()
    const location = useLocation();
    const { collapseSidebar, collapsed } = useProSidebar();
    const [dateFilter, setDateFilter] = useState()
    const [loading, setLoading] = useState(false);
    const [fromDate, setFromDate] = useState({});
    const [toDate, setToDate] = useState({})
    const [clickedRequestGroupId, setClickedRequestGroupId] = useState()
    const [doctorPatients, setDoctorPatients] = useState([]);
    const [showProfile, setShowProfile] = useState(false);
    const [isMobile, setIsMobile] = useState()

    useEffect(() => {
        /*     getDepartmentInfo((isOk, data) => {
                if (!isOk) return alert(data.message);
    
                else return setDepartmentInfo(data)
            }) */
        if (window.innerWidth < 600) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [])

    useEffect(() => {
        if (location.state?.s && location.state?.m) {
            setLoading(true)
            getDoctorPatients(location.state?.m, location.state?.s, fromDate?.date, toDate?.date, (isOk, data) => {
                setLoading(false)
                if (!isOk) {
                    return alert(data.message);
                }
                else {
                    if (data.length === 0) {
                        alert('پزشکی با این مشخصات یافت نشد!')
                        window.location.replace("/")
                    }
                    else {
                        return setDoctorPatients(data)
                    }
                }
            })
        }
    }, [location.state?.s, location.state?.m, dateFilter, fromDate.date, toDate.date])

    return (
        <>
            <div style={{ background: "#F6F7FA" }}>
                <main className='flex'>

                    <Sidebar
                        transitionDuration={800}
                        backgroundColor="#F6F7FA"
                        rtl={true}
                        style={{ height: "100vh", width: isMobile === undefined ? 0 : "80px" }}
                        defaultCollapsed={isMobile}
                    >
                        <Menu>
                            <MenuItem
                                icon={<MenuOutlinedIcon />}
                                onClick={() => {
                                    collapseSidebar();
                                }}
                                style={{ textAlign: "center" }}
                            >
                                <img src="/images/Vahed Parto Logo - 1.png" alt='logo' style={{ width: '100px' }} />
                            </MenuItem>
                            <MenuItem icon={<img src='/images/doctor2.svg' alt='doctor' style={{ width: "24px" }} />} style={{ border: "1px solid #3ecdd8", margin: collapsed ? "0" : '0.5rem', borderRadius: "10px", color: "#3ecdd8" }}>{doctorPatients[0]?.NameDoctorPatient}</MenuItem>
                            <Divider style={{ width: "90%" }} />
                            <MenuItem icon={<HomeOutlinedIcon />} onClick={() => navigate("/")} style={{ marginTop: "1rem" }}>سایت کلینیک</MenuItem>
                            <MenuItem onClick={() => { setDateFilter("week"); setShowProfile(false); }} icon={<TodayOutlinedIcon />}>بیماران هفته جاری</MenuItem>
                            <MenuItem onClick={() => { setDateFilter("month"); setShowProfile(false); }} icon={<DateRangeOutlinedIcon />}>بیماران ماه جاری</MenuItem>
                            <MenuItem onClick={() => { setDateFilter("year"); setShowProfile(false); }} icon={<CalendarMonthOutlinedIcon />}>بیماران سال جاری</MenuItem>
                            <MenuItem onClick={() => setShowProfile(true)} icon={<PersonOutlineOutlinedIcon />}>پروفایل من</MenuItem>
                            {/* <MenuItem onClick={() => window.open("https://gerafi.com", "_blank")} icon={<HelpOutlineOutlinedIcon />}>درباره ما</MenuItem> */}
                        </Menu>
                    </Sidebar>
                    <section className="pt-4 sm:pr-4 w-full h-screen">
                        {!showProfile ? <PatientTable setClickedRequestGroupId={setClickedRequestGroupId} clickedRequestGroupId={clickedRequestGroupId} setFromDate={setFromDate} fromDate={fromDate} setToDate={setToDate} toDate={toDate} data={doctorPatients} dateFilter={dateFilter} /> : <Profile />}
                    </section>
                </main>
            </div>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
