import { getAxiosInstanceApi } from "./api";
import { cachiosInstance } from "./cacheApi";

export const getPatientReport = (receptionCode, callback) => {
    cachiosInstance.get(`/vahed/patientReport/${receptionCode}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getDoctorPatients = (medDoctor, secDoctor, fromDate,toDate, callback) => {
    cachiosInstance.get(`/vahed/doctorPatients/${medDoctor}/${secDoctor}${fromDate && toDate ? `?fromDate=${fromDate}&toDate=${toDate}` :""}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getDoctorInformation = (medicalCouncil, callback) => {
    cachiosInstance.get(`/vahed/doctorInformation/${medicalCouncil}`)
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};
export const getMEDRequestGroup = (callback) => {
    cachiosInstance.get('/vahed/MEDRequestGroup')
        .then(response => {
            const data = response.data;
            callback(true, data.data);
        }).catch(error => {
            callback(false, error.response.data);
        })
};