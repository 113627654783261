import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import React, { useState } from 'react';
import { BsTelephone } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';

export default function FloatingBtn() {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <SpeedDial
                ariaLabel='speedDial'
                icon={<SpeedDialIcon sx={{ color: '#fff' }} />}
                onClose={handleClose}
                onOpen={handleOpen}
                FabProps={{ style: { background: "rebeccapurple" } }}
                open={open}
                direction="up"
                className='fixed right-[15px] sm:right-[30px] bottom-[15px] sm:bottom-[30px] z-[1000]'
            >
                <SpeedDialAction
                    icon={<a href='https://www.google.com/maps/dir/?api=1&destination=36.29288005472505, 59.580102403389006&travelmode=driving' target='_blank' rel="noopener noreferrer">
                        <MdLocationOn size='18px' /></a>}
                    tooltipTitle={'مسیردهی'}
                    sx={{ '& .MuiSpeedDialAction-staticTooltipLabel': { minWidth: '90px', fontSize: '14px' } }}
                    tooltipOpen

                />\
                <SpeedDialAction
                    icon={<a href='tel:05138883'><BsTelephone size='18px' /></a>}
                    tooltipTitle={'پذیرش'}
                    tooltipOpen
                    sx={{ '& .MuiSpeedDialAction-staticTooltipLabel': { minWidth: '90px', fontSize: '14px' } }}
                />
            </SpeedDial>
        </>
    )
}
