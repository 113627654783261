import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Box, createTheme, Modal, TextField, ThemeProvider } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import PatientModal from '../components/PatientModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 4
};

export default function Home() {
    const [patientModalOpen, setPatientModalOpen] = useState(false);
    const [doctorModalOpen, setDoctorModalOpen] = useState(false);
    const [secretDoctor, setSecretDoctor] = useState("");
    const [medDoctor, setMedDoctor] = useState("");
    const submitRef = useRef(null)
    const navigate = useNavigate()
    const handleOpenPatient = () => setPatientModalOpen(true);
    const handleClosePatient = () => setPatientModalOpen(false);
    const handleOpenDoctor = () => setDoctorModalOpen(true);
    const handleCloseDoctor = () => setDoctorModalOpen(false);


    const secretDoctorChange = (event) => {
        setSecretDoctor(event.target.value)
    }
    const medDoctorChange = (event) => {
        setMedDoctor(event.target.value)
    }
    const onDoctorClickEnter = (event) => {
        if (event.key === 'Enter' && secretDoctor !== '') {
            event.preventDefault();
            event.stopPropagation();
            submitRef.current.click()
        }
    }
    const theme = createTheme({
        direction: 'rtl',
        typography: {
            "fontFamily": "IRANYekan"
        }
    });

    const cacheRtl = createCache({
        key: 'muirtl',
        stylisPlugins: [prefixer, rtlPlugin],
    });

    const redirectToPortal = () => {
        navigate('/doctor-patients', { state: { m: medDoctor, s: secretDoctor } })
    }
    return (
        <>
            <div className="h-full flex flex-col items-center justify-center" style={{ background: "url('/images/VahedParto-Site copy2.jpg') center / cover no-repeat" }}>
                <p className='pb-6 sm:pb-12 text-white sm:text-3xl text-lg text-center'>کلینیک رادیولوژی و سونوگرافی واحد پرتو</p>

                <div className='flex flex-col sm:flex-row flex-wrap sm:gap-4 justify-center'>
                    <section onClick={handleOpenDoctor} className='w-[180px] h-[180px] sm:w-[200px] sm:h-[200px] md:w-[220px] md:h-[220px] hover:border-2 opacity-80 hover:opacity-100 transition-all border-black hover:cursor-pointer rounded-lg bg-white flex flex-col items-center justify-center sm:ml-4 mt-4 sm:mt-0'>
                        <img src="/images/doctor.svg" alt='doctor' className='sm:w-[60px] w-[50px]' />
                        <p className='mt-6 text-sm sm:text-base font-bold'>پرتال پزشکان</p>
                    </section>
                    <section onClick={handleOpenPatient} className='w-[180px] h-[180px] sm:w-[200px] sm:h-[200px] md:w-[220px] md:h-[220px] hover:border-2 opacity-80 hover:opacity-100 transition-all border-black hover:cursor-pointer rounded-lg bg-white flex flex-col items-center justify-center sm:ml-4 mt-4 sm:mt-0'>
                        <img src="/images/patient.svg" alt='doctor' className='sm:w-[60px] w-[50px]' />
                        <p className='mt-6 text-sm sm:text-base font-bold'>جوابدهی بیماران</p>
                    </section>
                    <section className='w-[180px] h-[180px] opacity-40 sm:w-[200px] sm:h-[200px] md:w-[220px] md:h-[220px] hover:border-2 transition-all border-black rounded-lg flex bg-white flex-col items-center justify-center mt-4 sm:mt-0'>
                        <img src="/images/pacs.svg" alt='doctor' className='sm:w-[60px] w-[50px]' />
                        <p className='mt-6 text-sm sm:text-base font-bold'>ورود به پکس</p>
                    </section>
                </div>
                <p className='text-white text-sm pt-8'>ساخته شده با ❤️ در داده پردازی نوین بارثاوا</p>
            </div>
            <Modal open={doctorModalOpen} onClose={handleCloseDoctor}>
                <Box sx={style}>
                    <section>
                        <img src="/images/doctor-il.svg" alt='doctor' className="w-[300px] mx-auto" />
                        <div className='flex flex-col items-center mt-8'>
                            <CacheProvider value={cacheRtl}>
                                <ThemeProvider theme={theme}>
                                    <div dir="rtl" className='flex flex-col items-center flex-wrap'>
                                        <TextField type='tel' variant="outlined" value={medDoctor} onChange={medDoctorChange} label="شماره نظام پزشکی" sx={{ width: "300px" }} />
                                        <TextField onKeyDown={onDoctorClickEnter} variant="outlined" type='password' value={secretDoctor} onChange={secretDoctorChange} label="کلمه عبور" className='my-2' sx={{ width: "300px" }} />
                                    </div>
                                </ThemeProvider>
                            </CacheProvider>

                            <button onClick={redirectToPortal} type="button" className='focus:outline-none text-white bg-[#fa3668] font-medium rounded-lg px-8 sm:px-16 py-2.5 mr-4 mt-4'>ادامه</button>

                            <p className='text-sm sm:text-base mt-4 text-gray-500'>• درصورت داشتن سوال مي توانيد با شماره تلفن <a href='tel:05138883'>05138883</a> تماس بگيريد.</p>
                        </div>
                    </section>
                </Box>
            </Modal>
            {patientModalOpen && <PatientModal patientModalOpen={patientModalOpen} handleClosePatient={handleClosePatient} />}
        </>
    )
}
