import React from 'react';
import { createRoot } from 'react-dom/client'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProSidebarProvider } from 'react-pro-sidebar';
import Theme from './theme';
import { ThemeProvider } from '@mui/material';

const container = document.getElementById('root');
const root = createRoot(container);
root.render( <ProSidebarProvider><ThemeProvider theme={Theme}><App /></ThemeProvider></ProSidebarProvider>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
